<template>
  <Main/>
</template>

<script>
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    Main
  }
}
</script>
<style>
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: lightblue;
  background-color: #294445;
  margin-top: 4em;
  font-size: large;
}

a:link {
  color: lightblue;
}

a:visited {
  color: lightblue;
}

</style>
